<div class="part-search" *ngIf="isMobile"><part-search-field></part-search-field></div>
<div class="search-background">
  <div class="loading-container" data-cy="loading-search-container">
    <div class="sentence">
      <span>Searching Results on </span>
      <span *ngIf="animationNotStarted" class="words">{{ vendors[0].vendor }}</span>
      <div class="words rotate">
        <span>{{ vendors[1].vendor }}</span>
        <span>{{ vendors[2].vendor }}</span>
        <span>{{ vendors[3].vendor }}</span>
        <span>{{ vendors[4].vendor }}</span>
        <span>{{ vendors[5].vendor }}</span>
        <span>{{ vendors[6].vendor }}</span>
        <span>{{ vendors[7].vendor }}</span>
        <span>{{ vendors[8].vendor }}</span>
        <span>{{ vendors[9].vendor }}</span>
      </div>
      <br />
    </div>
    <div class="progress">
      <div class="bar" id="bar"></div>
    </div>
  </div>
</div>
