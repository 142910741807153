import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, NgClass, CurrencyPipe, AsyncPipe } from '@angular/common';
import { trigger, style, animate, transition, stagger, query } from '@angular/animations';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';
import { FeatureService } from '../_services/feature.service';
import { isMobile } from '../_util/mobile.util';
import { TooltipDirective } from '@limblecmms/lim-ui';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { pairwise, switchMap, filter, take } from 'rxjs/operators';
import { EventBusService } from '../_shared/event-bus.service';

import {
  IconComponent,
  MinimalIconButtonComponent,
  DropdownButtonComponent,
  DropdownTextItemComponent
} from '@limblecmms/lim-ui';
import { CustomPanelComponent } from '../custom-lim-ui-components/panel/panel.component';
import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { BadgeComponent } from '../badge/badge.component';
import { PreSearchBestMatchComponent } from '../pre-search-best-match/pre-search-best-match.component';

import { ItemLoadingAnimation } from '../animations/item-loading-animation/item-loading-animation';
import { SkeletonLoadingBarAnimation } from '../animations/skeleton-loading-bar-animation/skeleton-loading-bar-animation';

import { SkeletonSortAnimation } from '../animations/skeleton-sort-animation/skeleton-sort-animation';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';

@Component({
  selector: 'best-match-results',
  templateUrl: './best-match-results.component.html',
  styleUrls: ['./best-match-results.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    CurrencyPipe,
    IconComponent,
    MinimalIconButtonComponent,
    CustomPanelComponent,
    PartSearchItemCardComponent,
    AsyncPipe,
    DropdownButtonComponent,
    DropdownTextItemComponent,
    ItemLoadingAnimation,
    SkeletonLoadingBarAnimation,
    SkeletonSortAnimation,
    ShippingDetailsComponent,
    FontAwesomeModule,
    TooltipDirective,
    BadgeComponent,
    PreSearchBestMatchComponent
  ]
  // Animations sometimes cause glitchy behavior (brief duplicate cards)
  // when messing with vendor filters, disabling for now
  // This seems to only happen on the first interaction with "slow vendors" probably because it swaps the full item
  // list out on first interaction
  //animations: [trigger('hideItem', [transition(':leave', [query('.item', [animate(300, style({ opacity: 0.5 }))])])])]
})
export class BestMatchResultsComponent implements OnInit, OnDestroy {
  @Output() collapseIrrelevantResults: EventEmitter<boolean> = new EventEmitter<boolean>();

  private searchCycle = new BehaviorSubject<number>(0);
  public heroItem = {
    id: 0,
    title: '',
    description: null,
    price: 0,
    pricePer: 0,
    per: 1,
    mainImageUrl: '',
    productUrl: null,
    manufacturerPartNumber: null,
    savings: null,
    vendor: {
      id: null,
      logoUrl: '',
      name: null,
      key: null
    },
    labels: {},
    items: [],
    hideVendorLogo: false,
    user_hidden: false,
    shipping_days: null,
    inStock: false,
    matches: [] as ItemGroupMatch[],
    source: null,
    isLocked: false
  };

  public bestMatch = { ...this.heroItem };

  public searchQuery: string | null = '';
  public groupedItems: any = [];
  public items: any = [];
  public sortByLabels: any = {
    'price_per:asc': 'Price (Low to High)',
    'price_per:desc': 'Price (High to Low)',
    relevance: 'Relevance'
  };
  public readonly _pagePositionFlagOne = new BehaviorSubject<boolean>(false);
  public readonly _pagePositionFlagTwo = new BehaviorSubject<boolean>(false);
  readonly pagePositionFlagOne$ = this._pagePositionFlagOne.asObservable();
  readonly pagePositionFlagTwo$ = this._pagePositionFlagTwo.asObservable();
  public sortBy: string = 'relevance';
  public groupedResultLimit: number = 5;
  public showRelevantResults: boolean = true;
  public relevantResults: any = [];
  public removeBestMatch: boolean = false;
  public dollarCircle = faDollarCircle;
  public lockKeyhole = faLockKeyhole;
  public showLabelControls = false;
  public showSmartGroupings: boolean = false;
  public vendorTooltip: string = '';
  public isMobile: boolean = false;
  public isItemsRestricted: boolean = false;
  public vendorLogoKeys: string[] = [];
  public match: ItemGroupMatch | undefined;
  public faSparkles = faSparkles;
  public badgeLabel: string = '';
  public selectedAttributeGroup: SelectedAttributeGroups | null = null;
  public filters: any = {};
  public preSearchBestMatchItems: any = [];
  public preSearchPresent: boolean = false;
  public pastSearchHistoryResult: boolean = false;

  public querySub?: Subscription;
  public bestMatchSub?: Subscription;
  public sortBySub?: Subscription;
  public relevantResultsSub?: Subscription;
  public savePagePositionsSub?: Subscription;
  public showBestMatchSub?: Subscription;
  public isItemsRestrictedSub?: Subscription;
  public featureServiceSub?: Subscription;
  public searchResultsMetaSub?: Subscription;
  public pastSearchSub?: Subscription;

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    private readonly router: Router,
    private featureService: FeatureService,
    private readonly eventBus: EventBusService
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.featureServiceSub = this.featureService.features$.subscribe((features) => {
      this.showLabelControls = this.featureService.enabled('super-admin');
      this.showSmartGroupings = this.featureService.enabled('smart-groupings');
    });

    this.querySub = this.searchService.query$.subscribe((query) => {
      this.searchQuery = query;
    });

    // When searchComplete changes from true to false, reset important values.
    this.searchService.searchComplete$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev && !curr)
      )
      .subscribe(() => {
        this.reset();
      });

    this.isItemsRestrictedSub = this.searchService.isItemsRestricted$.subscribe((isItemsRestricted) => {
      this.isItemsRestricted = isItemsRestricted;
      this.setLockedItems();
    });

    this.bestMatchSub = combineLatest([
      this.searchService.bestMatch$,
      this.searchService.selectedAttributeGroups$,
      this.searchService.searchComplete$,
      this.searchService.filters$
    ]).subscribe(([bestMatchItem, attributeGroup, isComplete]) => {
      this.selectedAttributeGroup = attributeGroup;

      if (!bestMatchItem || typeof bestMatchItem.id === 'undefined' || !bestMatchItem.id) {
        return;
      }

      if (this.isItemsRestricted) {
        bestMatchItem.isLocked = true;
      }

      // prevents bad UX from loading an incomplete best match item
      if (bestMatchItem.mainImageUrl && bestMatchItem.vendor && bestMatchItem.vendor.logoUrl && bestMatchItem.title) {
        this.bestMatch = bestMatchItem;
        this.heroItem = bestMatchItem;
      }

      this.items = [];
      this.groupedItems = [];

      if (attributeGroup) {
        const group = Object.values(attributeGroup)[0];
        const match = bestMatchItem.matches?.find(({ itemGroupName }) => itemGroupName === group?.name);
        this.match = { ...match, vendors: [] };

        if (this.match && this.match.items) {
          const items = this.searchService.filterGroupItems(match.items);
          const vendors = this.searchService.getVendorsFromFilteredGroupItems(items);
          const uniqueVendorIds = new Set();
          const uniqueVendorItems: any[] = [];
          items.forEach((item) => {
            if (!uniqueVendorIds.has(item.vendor.id)) {
              uniqueVendorItems.push(item);
              uniqueVendorIds.add(item.vendor.id);
            }
          });
          this.groupedItems = uniqueVendorItems.slice(0, 5);
          this.items = uniqueVendorItems.slice(0, 5);
          this.match.count = items.length;
          this.match.vendors = vendors;

          if (this.items.length && this.items[0].id !== this.heroItem.id) {
            this.heroItem = this.items[0];
          }

          this.setLockedItems();
        }
      }

      if (this.selectedAttributeGroup) {
        const group = Object.values(this.selectedAttributeGroup)[0];

        if (this.isMobile) {
          this.badgeLabel = `${this.match ? this.match.itemGroupValue : 'Not Found'}`;
        } else {
          this.badgeLabel = `${group.name} \u2022 ${
            this.match && this.match.itemGroupValue ? this.match.itemGroupValue : 'Not Found'
          }`;
        }
      }

      this.setVendorLogoKeys();
      this.buildToolTip();
      this.preSearchItems();

      // Flag if search is complete, for pagePosition routine.
      if (isComplete) {
        this._pagePositionFlagOne.next(true);
      }
    });

    this.sortBySub = this.searchService.sortBy$.subscribe((sortBy) => {
      this.sortBy = sortBy;
    });

    this.relevantResultsSub = this.searchService.relevantResults$.subscribe((relevantResults) => {
      this.relevantResults = relevantResults;
    });

    this.savePagePositionsSub = this.searchCycle
      .pipe(
        switchMap(() =>
          combineLatest([this.pagePositionFlagOne$, this.pagePositionFlagTwo$]).pipe(
            // Only trigger this once per search.
            filter(([one, two]) => one && two),
            take(1)
          )
        )
      )
      .subscribe(() => {
        // If this is a past result, don't attempt to save pagePositions for it.
        if (this.pastSearchHistoryResult) {
          console.log('past search detected');
          return;
        }
        let index = 1;
        if (!this.removeBestMatch) {
          const groupValueId = this.match?.itemGroupValueId;
          if (this.preSearchPresent) {
            this.preSearchBestMatchItems.forEach((item: any) => {
              this.itemsService.trackPagePosition(item.id, index, groupValueId);
            });
          } else {
            if (this.heroItem) {
              this.itemsService.trackPagePosition(this.heroItem.id, index, groupValueId);
            }
            this.groupedItems.forEach((item: any) => {
              this.itemsService.trackPagePosition(item.id, index, groupValueId);
            });
          }
        }
        if (this.showRelevantResults) {
          const relevantResults = this.relevantResultList();
          relevantResults.forEach((item: any) => {
            index = index + 1;
            let groupValueId = undefined;
            if (this.selectedAttributeGroup) {
              const group = Object.values(this.selectedAttributeGroup)[0];
              if (item.matches) {
                const match = item.matches.find(({ itemGroupId }) => itemGroupId === group?.id);
                if (match) {
                  groupValueId = match.itemGroupValueId;
                }
              }
            }
            this.itemsService.trackPagePosition(item.id, index, groupValueId);
          });
        }
        this.itemsService.savePagePositions();
      });

    this.showBestMatchSub = combineLatest([
      this.searchService.queryQuality$,
      this.searchService.isPreviewing$,
      this.searchService.searchComplete$,
      this.searchService.relevantResults$
    ]).subscribe((result) => {
      let partNumberFound = true;
      let queryQuality = result[0];
      let isPreviewing = result[1];
      let isComplete = result[2];

      if (!isPreviewing && !isComplete) {
        return;
      }

      if (queryQuality && queryQuality.facets?.part_number === true && !partNumberFound) {
        this.removeBestMatch = true;

        if (this.relevantResults.length >= 1) {
          this.showRelevantResults = true;
        } else {
          this.showRelevantResults = false;
        }
      } else {
        this.removeBestMatch = false;
        // this.collapseIrrelevantResults.emit(false);

        if (this.relevantResults.length === 1) {
          this.showRelevantResults = false;
        } else if (this.relevantResults.length > 1) {
          this.showRelevantResults = true;
        }
      }

      if (this.relevantResults.length === 0) {
        this.removeBestMatch = true;
        this.collapseIrrelevantResults.emit(true);
      }
      //else {
      //   this.collapseIrrelevantResults.emit(false);
      // }

      // Flag if search is complete, for pagePosition routine.
      if (isComplete) {
        this._pagePositionFlagTwo.next(true);
      }
    });

    this.pastSearchSub = combineLatest([this.searchService.isPastResult$, this.searchService.isCachedQuery$]).subscribe(
      ([past, cached]) => {
        this.pastSearchHistoryResult = past || cached;
      }
    );

    this.searchResultsMetaSub = this.searchService.searchResultsMeta$.subscribe((searchResultsMeta) => {
      if (searchResultsMeta.preSearchDetails) {
        this.preSearchPresent = true;
      } else {
        this.preSearchPresent = false;
      }
    });
  }

  setLockedItems() {
    if (!this.groupedItems || this.groupedItems.length === 0) {
      return;
    }
    this.groupedItems.forEach((item: any, index: number) => {
      item.isLocked = this.isItemsRestricted && index < 3;
    });
  }

  relevantResultList() {
    if (this.removeBestMatch) {
      return this.relevantResults;
    }

    return this.relevantResults.slice(1);
  }

  buildToolTip(): void {
    const vendors = this.match ? this.match.vendors : [];

    if (!vendors || vendors.length == 0) {
      this.vendorTooltip = '';
      return;
    }

    let tooltip = 'Compare this item on ';

    vendors.forEach((vendor: any, index: number) => {
      tooltip += vendor.name;
      if (index < vendors.length - 2) {
        tooltip += ', ';
      } else if (index === vendors.length - 2) {
        tooltip += ' and ';
      }
    });

    this.vendorTooltip = tooltip;
  }

  openItemSite(productUrl: any, vendor: any, itemId: number) {
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  getSortByLabel() {
    return 'Sort by: ' + this.sortByLabels[this.sortBy];
  }

  sortResults(value: any) {
    this.searchService.setSortBy(value);
    this.searchService.processResults();
  }

  selectItem(item: any) {
    this.heroItem = item;
    this.setVendorLogoKeys();
  }

  setVendorLogoKeys() {
    if (this.match && this.match.vendors && this.heroItem && this.heroItem.vendor) {
      this.vendorLogoKeys = this.match.vendors
        .filter((vendor: any) => vendor.id !== this.heroItem.vendor.id)
        .map(({ key }) => key);
    } else {
      this.vendorLogoKeys = [];
    }
  }

  showDetails(selectedItem: any) {
    if (selectedItem.isLocked) {
      window.location.href = 'https://limblecmms.com/search-waitlist';
      return;
    }

    this.itemsService.trackClick(selectedItem.id, 'details').subscribe();
    const queryParams = { group: this.match?.itemGroupValueId };
    this.searchService.setDetailsPageSelectedItem(selectedItem);
    this.searchService.setSelectedItem(selectedItem);
    this.searchService.startProgressBar();
    this.router.navigate(['/details', `${selectedItem.id}`], { queryParams });
  }

  setLabel(itemId: any, key: string, value: any) {
    if (this.searchService.getItemLabel(itemId, key) == value) {
      value = null;
    }
    this.itemsService.updateLabel(itemId, key, value).subscribe();
    this.searchService.setItemLabel(itemId, key, value);

    return false;
  }
  preSearchItems() {
    if (this.groupedItems.length) {
      this.preSearchBestMatchItems = [...this.groupedItems.slice(0, 5)];
    } else {
      this.preSearchBestMatchItems = [this.bestMatch];
    }
  }
  reset(): void {
    this._pagePositionFlagOne.next(false);
    this._pagePositionFlagTwo.next(false);
    this.pastSearchHistoryResult = false;
    this.itemsService.resetPagePositions();
    this.searchCycle.next(this.searchCycle.value + 1);
  }
  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
    this.bestMatchSub?.unsubscribe();
    this.sortBySub?.unsubscribe();
    this.relevantResultsSub?.unsubscribe();
    this.savePagePositionsSub?.unsubscribe();
    this.showBestMatchSub?.unsubscribe();
    this.isItemsRestrictedSub?.unsubscribe();
    this.featureServiceSub?.unsubscribe();
  }
}
