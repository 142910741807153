<section>
  <app-item-hidden-notification
    [item]="itemHiddenNotification"
    *ngIf="itemHiddenNotification"
    [notificationType]="'resultItem'"
  />
  <ng-container *ngIf="isMobile && slowVendors > 0">
    <div class="loading-vendors-banner" *ngIf="!(searchService.searchComplete$ | async)">
      <img src="/assets/images/loading-spinner-green.png" class="loading-spinner" alt="Loading Spinner" />
      Loading {{ slowVendors }} more vendors...
    </div>
  </ng-container>
  <ng-container *ngIf="!isMobile">
    <pre-search-hero></pre-search-hero>
    <div
      class="number-results part-number-header"
      *ngIf="showPartMissing && relevantResultsPresent && (searchService.isPreviewing$ | async)"
    >
      Missing: <span class="line-through">{{ partNumber }}</span> | Please check your search term and try again.
    </div>
    <div class="result-settings-container">
      <div class="result-settings">
        <div class="results-found">
          <div class="search-query">
            <span *ngIf="relevantResultsPresent" data-cy="search-query-results">
              >Results for <strong>"{{ lastQuery }}"</strong></span
            >
            <span *ngIf="!relevantResultsPresent" data-cy="search-query-no-results">
              No results for <strong>"{{ lastQuery }}"</strong></span
            >
          </div>

          <div class="number-results" *ngIf="relevantResultsPresent && (searchService.isPreviewing$ | async)">
            We found <span>{{ totalItems }}</span> {{ totalItems === 1 ? 'result' : 'results'
            }}<ng-container *ngIf="!(searchService.searchComplete$ | async)"> so far</ng-container>. Check each product page
            for more buying options.
          </div>

          <div class="number-results" *ngIf="!relevantResultsPresent">
            Sorry, we couldn’t find what you searched for. Please check your search term and try again.
          </div>

          <div class="results-loading-msg" [class]="{ hidden: (searchService.isPreviewing$ | async) }">
            <div>Hang tight while we find the best parts for you...</div>
          </div>
        </div>
        <delivery-location></delivery-location>
      </div>
    </div>
  </ng-container>
  <div class="search-results">
    <div>
      <best-match-results (collapseIrrelevantResults)="showIrrelevantResults($event)"> </best-match-results>
    </div>

    <div
      class="irrelevant-results-container"
      *ngIf="searchService.isPreviewing$ | async"
      [ngClass]="removeBestMatch ? 'no-margin' : ''"
    >
      <div *ngIf="(searchService.irrelevantResults$ | async)?.length > 0">
        <div class="irrelevant-results-collapsible" (click)="handleCollapserClick(); $event.stopPropagation()">
          See {{ (searchService.irrelevantResults$ | async)?.length }} results that are less relevant
          <lim-ui-minimal-icon-button
            [superMinimal]="true"
            [icon]="collapsedIcon"
            iconSize="small"
            class="icon-hover"
          ></lim-ui-minimal-icon-button>
        </div>
        <div class="show-irrelevant-results" *ngIf="collapsed">
          <div class="irrelevant-results-banner">
            <span class="header">More Results</span>
            <span class="text"
              >These results may not seem relevant, but some of them could be exactly what you're looking for.</span
            >
          </div>
          <div class="irrelevant-results">
            <part-search-item-card
              *ngFor="let item of searchService.irrelevantResults$ | async"
              [item]="item"
              id="{{ 'item' + item.id }}"
              [view]="'irrelevant-results'"
            ></part-search-item-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
