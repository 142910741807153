import { Component, inject, input, output, signal } from '@angular/core';
import { ModalComponent } from '../../../modal/modal.component';
import { SubscriptionService } from 'src/app/_services/subscription.service';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';

@Component({
  selector: 'upgrade-modal',
  standalone: true,
  imports: [ModalComponent, UpgradePlanComponent],
  templateUrl: './upgrade-modal.component.html',
  styleUrl: './upgrade-modal.component.scss'
})
export class UpgradeModalComponent {
  showUpgradeModal = input.required<boolean>();
  isSuperUser = input.required<boolean>();
  showThankYouModal = signal(false);
  hideUpgradeModal = output<void>();
  plans = signal([
    {
      name: 'Standard',
      price: 150,
      searches: 50,
      current: true,
      highlight: false,
      selected: false
    },
    {
      name: 'Premium +',
      price: 300,
      searches: 125,
      current: false,
      highlight: true,
      selected: false
    },
    {
      name: 'Enterprise',
      price: 500,
      searches: 250,
      current: false,
      highlight: false,
      selected: false
    }
  ]);

  private readonly subscriptionService = inject(SubscriptionService);

  selectedNewPlan(planName: string) {
    this.plans.update((plans) =>
      plans.map((plan) => ({
        ...plan,
        highlight: false,
        selected: plan.name === planName
      }))
    );
  }

  upgradeConfirmation() {
    this.hideUpgradeModal.emit();

    this.subscriptionService.sendUpgradeSubscriptionEmail().subscribe({
      next: (data: any) => {
        this.showThankYouModal.set(true);
      },
      error: (error) => {
        this.showThankYouModal.set(true);
        console.error(error);
      }
    });
  }
}
