import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CONFIG } from 'src/environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription, SubscriptionTier, User } from '../_types/user';
import { tap } from 'rxjs/operators';

const subscriptionUrl = CONFIG.API_URL + 'subscription';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private user = this.authService.user;

  sendUpgradeSubscriptionEmail() {
    if (!this.user()) {
      throw new Error('User not found');
    }

    if (this.user() && this.user()?.superUser) {
      return this.http.post(subscriptionUrl + '/upgrade', { userId: this.user()?.id }, httpOptions);
    }

    return this.http.post(subscriptionUrl + '/upgrade/request', { userId: this.user()?.id }, httpOptions);
  }

  createSubscription(subscriptionTierId: number) {
    const user = this.user();
    if (!user) {
      throw new Error('User not found');
    }

    const { customer } = user;

    return this.http.post<Subscription>(subscriptionUrl, { customerId: customer.id, subscriptionTierId }, httpOptions).pipe(
      tap((subscription) => {
        const currentUser = this.user();
        if (currentUser) {
          this.authService.saveUser({
            ...currentUser,
            customer: {
              ...currentUser.customer,
              subscriptions: [subscription]
            },
            searchesRemaining: subscription.subscriptionTier.ceiling
          });
        }
      })
    );
  }

  getSubscriptionTierByName(name: string) {
    return this.http.get<SubscriptionTier>(subscriptionUrl + '/tier', {
      ...httpOptions,
      params: { name }
    });
  }
}
