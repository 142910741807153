import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CONFIG } from '../../environments/environment';
import { TrialType } from '../_types/trialType';
import { AuthService } from './auth.service';
import { toSignal } from '@angular/core/rxjs-interop';

const trialUrl = CONFIG.API_URL + 'trial';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class TrialService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  private user = this.authService.user;

  sendTrialRequestEmail() {
    const user = this.authService.user();
    if (!user) {
      throw new Error('User not found');
    }
    return this.http.post(`${trialUrl}/request`, { userId: user.id }, httpOptions);
  }

  sendTrialNotificationEmail() {
    const user = this.authService.user();
    if (!user) {
      throw new Error('User not found');
    }

    return this.http.post(`${trialUrl}/notify`, { userId: user.id }, httpOptions);
  }
}
