import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss'],
  standalone: true
})
export class PageBanner {
  public showBanner: boolean = true;

  closeBanner() {
    this.showBanner = false;
    this.closedBanner.emit();
  }

  @Output() closedBanner = new EventEmitter();
}
