@if (showCMMSRegisterForm()) {
  <signup-flyout (close)="hideCMMSRegisterForm()">
    <cmms-register-form (submitted)="registerTrial()" />
  </signup-flyout>
} 
@if (showCMMSCustomerTrialThankYouModal()) {
  <modal (close)="hideCMMSCustomerTrialThankYouModal()">
    <trial-thank-you variant="trial-initiate" (done)="hideCMMSCustomerTrialThankYouModal()" />
  </modal>
}


@if (showCMMSGenericUserModal()) {
  <modal (close)="hideCMMSGenericUserModal()">
    <div class="modal-container">
      <h3>Request a 30-day free trial!</h3>
      <p>Sorry, you don't have permission to use Limble Search yet. We will send an email to your administrator <strong>({{ superUserEmail() }})</strong> to let them know you're interested and CC you on the email.</p>
      <button (click)="sendRequest()">Request Free Trial</button>
    </div>
  </modal>
}

@if (showCMMSGenericUserThankYouModal()) {
  <modal (close)="hideCMMSGenericUserThankYouModal()">
    <trial-thank-you variant="trial-interest" (done)="hideCMMSGenericUserThankYouModal()" />
  </modal>
}

@if (showTrialNotificationEmailThankYouModal()) {
  <modal (close)="hideTrialNotificationEmailThankYouModal()">
    <trial-thank-you variant="trial-ending" (done)="hideTrialNotificationEmailThankYouModal()" />
  </modal>
}