import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { SearchService } from './search.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../_types/user';
import { CONFIG } from '../../environments/environment';
import { catchError, map, Observable, of, tap } from 'rxjs';
const userUrl = CONFIG.API_URL + 'users';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class AuthGuardService {
  private authService: AuthService = inject(AuthService);
  private httpClient: HttpClient = inject(HttpClient);
  private router: Router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const user = this.authService.user();
    if (user) {
      return of(true);
    }

    return this.httpClient.get<User>(`${userUrl}`, httpOptions).pipe(
      tap((user) => {
        this.authService.saveUser(user);
      }),
      map(() => true),
      catchError(() => {
        const allowAll = route.data['allowAll'] || false;
        if (!allowAll) {
          this.router.navigate(['login']);
        }

        return of(allowAll);
      })
    );
  }
}
