{
  "name": "partosphere-ui",
  "version": "1.7.16-e121907f5-20241219-173130",
  "scripts": {
    "start": "ng serve",
    "start-local": "ng serve --configuration local",
    "start-dev": "ng serve --configuration development",
    "start-staging": "ng serve --configuration staging",
    "start-prod": "ng serve --configuration production",
    "build": "ng build --configuration staging",
    "build-local": "ng build --configuration local",
    "build-dev": "npm run config-secrets -- environment.development.ts && ng build --configuration development",
    "build-staging": "npm run config-secrets -- environment.staging.ts && ng build --configuration staging",
    "build-prod": "npm run config-secrets -- environment.production.ts && ng build --configuration production",
    "build-cmms-ui": "rm -rf designSystems/cmms-ui/dist && npx ng build cmms-ui",
    "config-secrets": "node config_secrets.js",
    "watch": "ng build --watch --configuration development",
    "watch-local": "ng build --watch --configuration local",
    "watch-dev": "ng build --watch --configuration development",
    "watch-staging": "ng build --watch --configuration staging",
    "watch-prod": "ng build --watch --configuration production",
    "test": "echo 'we dont have any tests'",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix",
    "prettier:check": "prettier --check \"**/*.ts\"",
    "prettier:fix": "prettier --write \"**/*.ts\"",
    "ng": "ng",
    "ng-update": "ng update @angular/cli @angular/core",
    "ng-cache-clean": "ng cache clean"
  },
  "engines": {
    "node": ">=20.14.0"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@datadog/browser-rum": "^4.46.0",
    "@floating-ui/dom": "^1.6.10",
    "@fontsource/metropolis": "^4.5.3",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-brands-svg-icons": "^6.6.0",
    "@fortawesome/pro-duotone-svg-icons": "^6.6.0",
    "@fortawesome/pro-light-svg-icons": "^6.6.0",
    "@fortawesome/pro-regular-svg-icons": "^6.6.0",
    "@fortawesome/pro-solid-svg-icons": "^6.6.0",
    "@json2csv/plainjs": "^7.0.3",
    "@limblecmms/lim-ui": "~1.9.11",
    "@ng-web-apis/common": "^2.1.0",
    "@ng-web-apis/resize-observer": "^2.0.0",
    "@popperjs/core": "2.11.8",
    "@solidexpert/ng-click-outside": "^14.0.2",
    "bootstrap": "^5.0.0",
    "date-fns": "3.6.0",
    "lottie-web": "^5.12.2",
    "ng-recaptcha": "^13.2.1",
    "ngx-lottie": "^12.0.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "~7.8.1",
    "tabulator-tables": "^5.5.2",
    "ts-evaluator": "^1.2.0",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "~18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@types/jasmine": "~4.3.0",
    "@types/node": "^20.11.6",
    "cypress": "^13.17.0",
    "eslint": "^8.57.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jest": "^27.1.7",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-security": "^1.5.0",
    "jasmine-core": "~4.5.0",
    "jest": "^29.3.1",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "ng-packagr": "18.2.1",
    "prettier": "^2.8.8",
    "typescript": "~5.5.4"
  }
}
