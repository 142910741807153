@if (showUpgradeModal()) {
    <modal (close)="hideUpgradeModal.emit()">
        <div class="upgrade-container">
            @if (isSuperUser()) {
                <h3>Upgrade your plan</h3>
                <p>Choose the best plan for your business</p>
                @for (plan of plans(); track plan.name) {
                    <upgrade-plan [plan]="plan" (selectedPlan)="selectedNewPlan($event)" />
                }
                <button (click)="upgradeConfirmation()">Upgrade Now</button>
            }
            @else {
                <h1>Upgrade Confirmation</h1>
                <p>Are you sure you want to upgrade your plan? By clicking upgrade, an email will be sent to the super user of your account.</p>
                <button (click)="upgradeConfirmation()">Upgrade</button>
            }
        </div>
    </modal>
  }
  
  @if (showThankYouModal()) {
    <modal (close)="showThankYouModal.set(false)">
      <div class="upgrade-container">
        <img src="assets/images/thank-you-icon.svg" />
        <h1>Thank you!</h1>
        @if (isSuperUser()) {
            <p>We are processing your upgrade request and should be active shortly.</p>
        }
        @else {
            <p>An email has been sent to the super user of your account.</p>
        }
        <button (click)="showThankYouModal.set(false)">Done</button>
      </div>
    </modal>
  }