import { inject, Injectable, signal, computed, Signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../../environments/environment';
import { toObservable } from '@angular/core/rxjs-interop';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private readonly _features = signal<Record<string, boolean>>({});

  private readonly features = computed(() => {
    if (Object.keys(this._features()).length > 0) {
      return this._features();
    }

    return this.getFeatures();
  });

  readonly features$ = toObservable(this.features);

  private readonly http = inject(HttpClient);

  enabled(feature: string): boolean {
    const features = this.features();

    return typeof features[feature] !== 'undefined' && features[feature] === true;
  }

  setFeatures(features: Record<string, boolean>): void {
    this._features.set(features);
  }

  getFeatures(): Signal<Record<string, boolean>> {
    this.http.get(CONFIG.API_URL + 'features', httpOptions).subscribe({
      next: (data: any) => {
        this._features.set(data);
      },
      error: (response) => {}
    });

    return this._features;
  }
}
