import { Component, inject, OnInit, signal } from '@angular/core';
import { CmmsRegisterFormComponent } from './cmms-register-form/cmms-register-form.component';
import { ModalComponent } from '../modal/modal.component';
import { TrialThankYouComponent } from './trial-thank-you/trial-thank-you.component';
import { SignupFlyoutComponent } from '../signup-flyout/signup-flyout.component';
import { EventBusService, MetaData } from '../_shared/event-bus.service';
import { TrialType } from '../_types/trialType';
import { SubscriptionService } from '../_services/subscription.service';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TrialService } from '../_services/trial.service';

@Component({
  selector: 'cmms-trial',
  standalone: true,
  imports: [CmmsRegisterFormComponent, ModalComponent, TrialThankYouComponent, SignupFlyoutComponent],
  templateUrl: './cmms-trial.component.html',
  styleUrl: './cmms-trial.component.scss'
})
export class CmmsTrialComponent implements OnInit {
  public showCMMSRegisterForm = signal(false);
  public showCMMSCustomerTrialThankYouModal = signal(false);
  public showCMMSGenericUserModal = signal(false);
  public showCMMSGenericUserThankYouModal = signal(false);
  public showTrialNotificationEmailThankYouModal = signal(false);
  private readonly eventService = inject(EventBusService);
  private readonly subscriptionService = inject(SubscriptionService);
  private readonly trialService = inject(TrialService);
  private readonly authService = inject(AuthService);
  public readonly superUserEmail = toSignal(this.authService.superUserEmail$);

  ngOnInit(): void {
    this.eventService.subscribe('CMMSSignupFlyout.Show', (callback: MetaData<boolean>) => {
      this.showCMMSRegisterForm.set(callback.data);
    });

    this.eventService.subscribe('CMMSSignUpTrialThankYouModal.Show', (callback: MetaData<boolean>) => {
      this.showCMMSCustomerTrialThankYouModal.set(callback.data);
    });

    this.eventService.subscribe('CMMSGenericUserModal.Show', (callback: MetaData<boolean>) => {
      this.showCMMSGenericUserModal.set(callback.data);
    });

    this.eventService.subscribe('CMMSGenericUserThankYouModal.Show', (callback: MetaData<boolean>) => {
      this.showCMMSGenericUserThankYouModal.set(callback.data);
    });

    this.eventService.subscribe('TrialNotificationEmailThankYouModal.Show', (callback: MetaData<boolean>) => {
      this.showTrialNotificationEmailThankYouModal.set(callback.data);
    });
  }

  registerTrial() {
    this.subscriptionService
      .getSubscriptionTierByName(TrialType.NEW_BIZ_CMMS)
      .pipe(switchMap((trialTier) => this.subscriptionService.createSubscription(trialTier.id)))
      .subscribe(() => {
        this.showCMMSRegisterForm.set(false);
        this.showCMMSCustomerTrialThankYouModal.set(true);
      });
  }

  hideCMMSRegisterForm() {
    this.showCMMSRegisterForm.set(false);
  }

  hideCMMSGenericUserModal() {
    this.showCMMSGenericUserModal.set(false);
  }

  hideCMMSGenericUserThankYouModal() {
    this.showCMMSGenericUserThankYouModal.set(false);
  }

  hideCMMSCustomerTrialThankYouModal() {
    this.showCMMSCustomerTrialThankYouModal.set(false);
  }

  hideTrialNotificationEmailThankYouModal() {
    this.showTrialNotificationEmailThankYouModal.set(false);
  }

  sendRequest() {
    this.trialService.sendTrialRequestEmail().subscribe({
      next: (data: any) => {
        this.showCMMSGenericUserModal.set(false);
        this.showCMMSGenericUserThankYouModal.set(true);
      },
      error: (error) => {
        this.showCMMSGenericUserModal.set(false);
        console.error(error);
      }
    });
  }
}
