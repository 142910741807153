import { CurrencyPipe } from '@angular/common';
import { Component, input, output, viewChild } from '@angular/core';

@Component({
  selector: 'upgrade-plan',
  standalone: true,
  imports: [CurrencyPipe],
  templateUrl: './upgrade-plan.component.html',
  styleUrl: './upgrade-plan.component.scss'
})
export class UpgradePlanComponent {
  plan = input.required<{
    name: string;
    price: number;
    searches: number;
    current: boolean;
    highlight: boolean;
    selected: boolean;
  }>();

  selectedPlan = output<string>();

  selectPlan() {
    this.selectedPlan.emit(this.plan().name);
  }
}
