import { Component, input, output } from '@angular/core';
@Component({
  selector: 'trial-thank-you',
  standalone: true,
  imports: [],
  templateUrl: './trial-thank-you.component.html',
  styleUrl: './trial-thank-you.component.scss'
})
export class TrialThankYouComponent {
  variant = input.required<'trial-initiate' | 'trial-interest' | 'trial-ending'>();
  done = output();
}
