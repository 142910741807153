<div id="app" [attr.app-version]="version">
  <navigation [isMobile]="isMobile()" [isLoggedIn]="isLoggedIn()" [responsivePositioning]="responsivePositioning()" />
  <search-complete-notification />
  <main [ngClass]="responsivePositioning() ? 'main-static' : 'main-fixed'">
    <default-trial />
    <cmms-trial />

    @switch (userAccessStatus()) {
      @case (userAccessStatusEnum.SearchCountExceeded) {
        <page-banner class="search-count-exceeded">          
          <div content class="msg">
            Maximum searches reached for this month. Your limit will reset on {{ periodResetDate() }}, or upgrade to continue.
            <a routerLink="/upgrade" class="cta">Upgrade Now</a>
          </div>
        </page-banner>
      }

      @default {
        @if (routeExtensionBannerEnabled() && showExtensionBanner) {
          <page-banner
           (closedBanner)="handleBannerClose()"
          >
            <div icon>
              <div class="pill">New</div>
            </div>
            <div content class="msg">
              Use Limble Search on Any Website.
              <a routerLink="/extension" class="cta">Learn More</a>
            </div>
          </page-banner>
        }
      }
    }

    <router-outlet></router-outlet>
    
  </main>
</div>
