import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
  standalone: true,
  imports: [RouterLink]
})
export class RegistrationSuccessComponent implements OnInit {
  // currentRoute: string;
  bannerText: string = '';
  bannerMessage: string = '';
  constructor(private router: Router) {}
  ngOnInit(): void {
    const currentRoute = this.router.url;
    if (currentRoute.includes('complete')) {
      this.bannerText = `Complete!`;
      this.bannerMessage = `If the username entered is valid, you will receive an email containing next steps`;
    }
  }
}
