<div class="main-container">
  @if (!isMobile()) {
    <div class="child-container">
      <div class="login-cover-image">
        <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
      </div>
    </div>
  }
  <div class="child-container">
    <login-form />
  </div>
</div>
