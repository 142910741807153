<upgrade-modal [showUpgradeModal]="showUpgradeModal()" [isSuperUser]="isSuperUser()" (hideUpgradeModal)="showUpgradeModal.set(false)" />

<div class="plan-and-usage-container">
  <div class="section-header">Plan Overview</div>
  @if (isSuperUser()) {
      <div class="plan-container">
        <plan-price [plan]="plan()" />
        <plan-status-pill [planStatus]="planStatus()" />
      </div>
      <total-searches-card [searches]="searches()" />
      <div class="update-plan-actions">
        <button (click)="upgrade()" class="upgrade-button">Upgrade Now</button>
        <p>*Your Limble Search subscription is included as part of your Limble CMMS plan and is billed together with CMMS.</p>
        <a class="manage-billing-link" href="#">Manage Billing Info in CMMS<fa-icon [icon]="manageBillingLinkIcon"></fa-icon></a>
        <a class="cancel-button" href="#">Cancel Subscription</a>
      </div>
    }
    @else {
      <div class="plan-container">
        <div class="plan-details">
          <h4>{{ plan().name }}</h4>
            <p>{{ searches().total }} searches/month</p>
          </div>
          <plan-status-pill [planStatus]="planStatus()" />
        </div>
        <total-searches-card [searches]="searches()" />
        <button (click)="upgrade()" class="upgrade-button">Upgrade Now</button>
    }
</div>
