import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { isMobile } from '../_util/mobile.util';

import { RegisterFormComponent } from '../register-form/register-form.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, RegisterFormComponent]
})
export class RegisterComponent implements OnInit, OnDestroy {
  public isMobile: boolean = false;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.isMobile = isMobile();
  }

  ngOnDestroy(): void {}
}
