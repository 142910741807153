import { Component, OnInit, output } from '@angular/core';
import { EventBusService } from '../_shared/event-bus.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../_services/notification.service';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '../_services/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'signup-flyout',
  templateUrl: './signup-flyout.component.html',
  styleUrls: ['./signup-flyout.component.scss'],
  standalone: true,
  imports: [FormsModule, FontAwesomeModule]
})
export class SignupFlyoutComponent {
  close = output();

  public faXmarkLarge: any = faXmarkLarge;

  constructor(
    private readonly eventBusService: EventBusService,
    public notificationService: NotificationService,
    public router: Router,
    public authService: AuthService
  ) {}
}
