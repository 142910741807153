<section>
  <div class="logo-animation-container" *ngIf="loading">
    <logo-animation [animationSize]="'large'" />
  </div>
  <div class="form-container" *ngIf="!loading">
    <h1 class="register-banner">Sign up to see all results</h1>
    <h2>Activate your 7-day free trial today.</h2>
    <form name="registerForm" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input
          type="text"
          class="form-control"
          name="firstName"
          [(ngModel)]="registerForm.firstName"
          required
          minlength="2"
          maxlength="50"
          #firstName="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && firstName.errors }"
        />
        <div class="invalid-feedback" *ngIf="firstName.errors && f.submitted">
          <div *ngIf="firstName.errors['required']">First name is required</div>
          <div *ngIf="firstName.errors['minlength'] || firstName.errors['maxlength']">
            First name should be between 2-50 characters long.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input
          type="text"
          class="form-control"
          name="lastName"
          [(ngModel)]="registerForm.lastName"
          required
          minlength="2"
          maxlength="50"
          #lastName="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && lastName.errors }"
        />
        <div class="invalid-feedback" *ngIf="lastName.errors && f.submitted">
          <div *ngIf="lastName.errors['required']">Last name is required</div>
          <div *ngIf="lastName.errors['minlength'] || lastName.errors['maxlength']">
            Last name should be between 2-50 characters long.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="companyName">Company Name</label>
        <input
          type="text"
          class="form-control"
          name="companyName"
          [(ngModel)]="registerForm.companyName"
          required
          minlength="2"
          maxlength="50"
          #companyName="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && companyName.errors }"
        />
        <div class="invalid-feedback" *ngIf="companyName.errors && f.submitted">
          <div *ngIf="companyName.errors['required']">Company name is required</div>
          <div *ngIf="companyName.errors['minlength'] || companyName.errors['maxlength']">
            Company name should be between 2-50 characters long.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="phoneNumber">Phone Number</label>
        <input
          type="tel"
          class="form-control"
          name="phoneNumber"
          [(ngModel)]="registerForm.phoneNumber"
          required
          phoneNumber
          #phoneNumber="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && phoneNumber.errors }"
        />
        <div class="invalid-feedback" *ngIf="phoneNumber.errors && f.submitted">
          <div *ngIf="phoneNumber.errors['required']">Phone Number is required</div>
          <div *ngIf="phoneNumber.errors['phoneNumber']">Phone Number must be valid.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email Address</label>
        <input
          type="email"
          class="form-control"
          name="email"
          [(ngModel)]="registerForm.email"
          required
          email
          #email="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
        />
        <div class="invalid-feedback" *ngIf="email.errors && f.submitted">
          <div *ngIf="email.errors['required']">Email is required</div>
          <div *ngIf="email.errors['email']">Email must be a valid email address</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="password-container">
          <input
            [type]="passwordVisible ? 'text' : 'password'"
            class="form-control"
            name="password"
            [(ngModel)]="registerForm.password"
            required
            #password="ngModel"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}"
            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
          />
          <div class="password-toggle">
            <lim-ui-icon
              [icon]="passwordVisible ? 'eyeRegular' : 'eyeSlashRegular'"
              iconSize="small"
              (click)="hideShowPassword()"
            ></lim-ui-icon>
          </div>
        </div>
        <div class="invalid-feedback" *ngIf="password.errors && f.submitted">
          <div *ngIf="password.errors['required']">Password is required!</div>
          <div *ngIf="password.errors['pattern']">
            Password must be at least 8 characters, contain an uppercase, lowercase, number, and special character.
          </div>
        </div>
      </div>
      <div class="error-container invalid-feedback" *ngIf="registrationError">
        {{ errorMsg }}
      </div>
      <lim-ui-radio-button
        [label]="'Standard trial (7days)'"
        [groupID]="'trialTypes'"
        (clickRadioButton)="selectTrial(TrialType.STANDARD)"
      />
      <lim-ui-radio-button
        [label]="'Existing CMMS Customers (30 days)'"
        [groupID]="'trialTypes'"
        (clickRadioButton)="selectTrial(TrialType.EXISTING_CMMS)"
      />
      <lim-ui-radio-button
        [label]="'New Business CMMS Customers (180 days)'"
        [groupID]="'trialTypes'"
        (clickRadioButton)="selectTrial(TrialType.NEW_BIZ_CMMS)"
      />
      <button type="submit" class="signup-button">Sign Up</button>
      <div class="signup-section">
        Already have an account?
        <a (click)="this.loginClick.emit()">Log In</a>
      </div>
      <div class="google-terms-section">
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </form>
    <div class="child-container" *ngIf="loading">
      <div class="logo-animation-container">
        <logo-animation [animationSize]="'large'" />
      </div>
    </div>
  </div>
</section>
