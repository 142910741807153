@if (showBanner) {
  <section class="full-page-banner">
    <div class="banner-content">
      <ng-content select="[icon]"></ng-content>
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="close" (click)="closeBanner()">&times;</div>
  </section>
}

