import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService, UserAccessStatus } from '../_services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO - Remove post MVP - const authToken = this.authService.getToken();
    // if(authToken) {
    //   req = req.clone({
    //     headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    //   });
    // }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          error.status >= 400 &&
          error.status < 500
        ) {
          return this.handle400Errors(req, next, error.status);
        }

        return throwError(() => error);
      })
    );
  }

  private handle400Errors(request: HttpRequest<any>, next: HttpHandler, errorCode: number) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      switch (errorCode) {
        case 401: {
          if (this.authService.isLoggedIn()) {
            this.authService.logout();
          }
          break;
        }

        case 403: {
          this.router.navigateByUrl('/');
          break;
        }

        default:
          break;
      }
    }

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }];
