import { Component, signal, input, computed, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { faPuzzlePiece, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { AuthService } from '../_services/auth.service';
import { MobileNavComponent } from '../mobile-nav/mobile-nav.component';
import { NgClass } from '@angular/common';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { DropdownComponent, DropdownTextItemComponent, IconComponent } from '@limblecmms/lim-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { toSignal } from '@angular/core/rxjs-interop';

import { FeatureService } from '../_services/feature.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../../environments/environment';
import { NotificationComponent } from '../notification/notification.component';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { TrialService } from '../_services/trial.service';
import { EventBusService } from '../_shared/event-bus.service';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [
    MobileNavComponent,
    NgClass,
    PartSearchFieldComponent,
    DropdownComponent,
    IconComponent,
    DropdownTextItemComponent,
    FontAwesomeModule,
    RouterLink,
    NotificationComponent
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
  public faPuzzlePiece: IconDefinition = faPuzzlePiece;
  public faClock: IconDefinition = faClock;
  isMobile = input.required<boolean>();
  isLoggedIn = input.required<boolean>();
  responsivePositioning = input.required<boolean>();

  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly trialService = inject(TrialService);
  private readonly eventBusService = inject(EventBusService);
  private readonly currentRoute = signal('');
  public readonly isCmmsUser = toSignal(this.authService.isCmmsUser$);
  showMobileMenu = signal(false);
  public daysInTrialRemaining = toSignal(this.authService.daysInTrialRemaining$);

  public shouldShowTrialNotification = computed(() => {
    const daysInTrialRemaining = this.daysInTrialRemaining();
    if (daysInTrialRemaining !== undefined) {
      return daysInTrialRemaining <= 7;
    }

    return false;
  });

  private readonly http = inject(HttpClient);
  constructor() {
    // because the navigation component is a parent node of the <router-outlet> the activated route has no context of the current route so we need to manually look up the router events to find the current route path.
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.url);
      }
    });
  }

  showPartSearchField = computed(() => {
    if (this.isLoggedIn()) {
      if (this.routesMatch(['/results', '/details'])) {
        if (!this.isMobile()) {
          return true;
        }
      }
    }

    return false;
  });

  showHamburger = computed(() => {
    if (!this.routesMatch(['/login', '/register'])) {
      return true;
    }

    return false;
  });

  showLinks = computed(() => {
    if (!this.isLoggedIn() && this.routesMatch(['/'])) {
      return true;
    }

    if (this.routesMatch(['/explore'])) {
      return true;
    }

    return false;
  });

  registerButtonCopy = computed(() => {
    return this.routesMatch(['explored']) ? 'Start Free Trial' : 'Sign Up';
  });

  private routesMatch(routes: string[]): boolean {
    return routes.filter((route) => this.currentRoute().includes(route)).length > 0;
  }

  toggleMobileMenu() {
    this.showMobileMenu.update((value) => !value);

    if (this.showMobileMenu()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  goToAccount() {
    this.router.navigate(['/account/profile']);
  }

  goToPlanAndUsage() {
    this.router.navigate(['/account/subscriptions']);
  }

  goToVendorPrefs() {
    this.router.navigate(['/'], { queryParams: { vendorPrefs: 1 } });
  }

  logout() {
    localStorage.removeItem('extensionBannerHidden');
    this.authService.logout();
  }

  notifyAdministrator() {
    this.trialService.sendTrialNotificationEmail().subscribe({
      next: (data) => {
        this.eventBusService.emit('TrialNotificationEmailThankYouModal.Show', true);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
