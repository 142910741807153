<nav [ngClass]="responsivePositioning() ? 'nav-static' : 'nav-fixed'">
  <a routerLink="/" class="logo">
    <img src="/assets/images/limble-search-logo.svg" />
  </a>

  @if (showPartSearchField()) {
    <part-search-field />
  }
  @if (isMobile()) {
    @if (showHamburger()) {
      <span
        class="hamburger-menu-wrapper"
        (click)="toggleMobileMenu()"
      >
        <lim-ui-icon icon="barsRegular" />
      </span>
    }
  }
  @else {
    @if (isLoggedIn()) {
      <div class="side-tab">
        <div class="link-container">
          @if (shouldShowTrialNotification()) {
            <notification>
              <fa-icon [icon]="faClock" />
              <div class="text">
                Free Trial Ending in <strong>{{ daysInTrialRemaining() }}</strong> days.
              <a (click)="notifyAdministrator()">Notify Administrator</a>
              </div>
            </notification>
          }
        </div>
        <div class="link-container">
          <fa-icon [icon]="faPuzzlePiece"></fa-icon>
          <a routerLink="/extension">Extension</a>
        </div>
        <div class="link-container">
          <lim-ui-icon icon="userRegular" iconSize="small" class="account-icon"></lim-ui-icon>
          <lim-ui-dropdown #dropdown [hoverDropdown]="true" [widthFitContent]="true" [placement]="'bottom-start'">
            <div button #dropdownButton class="account">Account</div>
            <div menu>
              <lim-ui-dropdown-text-item
                [closeDropdownOnClick]="true"
                (click)="goToAccount()"
                displayString="Edit Profile"
              ></lim-ui-dropdown-text-item>
              @if (isCmmsUser()) {
                <lim-ui-dropdown-text-item
                  [closeDropdownOnClick]="true"
                  (click)="goToPlanAndUsage()"
                  displayString="Plan &amp; Usage"
                ></lim-ui-dropdown-text-item>
              }
              <lim-ui-dropdown-text-item
                [closeDropdownOnClick]="true"
                (click)="goToVendorPrefs()"
                displayString="Vendor Preferences"
              ></lim-ui-dropdown-text-item>
              <lim-ui-dropdown-text-item
                class="logout"
                [closeDropdownOnClick]="true"
                (click)="logout()"
                displayString="Logout"
              ></lim-ui-dropdown-text-item>
            </div>
          </lim-ui-dropdown>
        </div>
      </div>
    }

    @if (showLinks()) {
      <div class="links">
        <a routerLink="/login">Login</a>
        <a href="https://limblecmms.com/search-waitlist" class="sign-up">
          {{ registerButtonCopy() }}
        </a>
      </div>
    } 
  }
</nav>

@if (isMobile()) {
  @if (showMobileMenu()) {
    <div [ngClass]="{ backdrop: showMobileMenu() }" (click)="toggleMobileMenu()"></div>
  }

  <div class="mobile-side-menu" [ngClass]="{ 'show-side-menu': showMobileMenu() }">
    <mobile-nav (hideMobileMenu)="toggleMobileMenu()"> </mobile-nav>
  </div>
}
