<div class="trial-thank-you-container">
  @switch (variant()) {
    @case ('trial-initiate') {
      <img src="assets/images/thank-you-icon.svg" />
      <h3>Thank you!</h3>
      <p>Your team now have full access to the tool for 30 days! We will remind you before the trial ends.</p>
      <button (click)="done.emit()">Done</button>
    }
    @case ('trial-interest') {
      <img src="assets/images/email-icon.svg" />
      <h3>Thank you for your interest in Limble Search!</h3>
      <p>Stay tuned! We've reached out to your administrator by email about the 30-day free trial.</p>
      <button (click)="done.emit()">Done</button>
    }
    @case ('trial-ending') {
      <img src="assets/images/email-icon.svg" />
      <h3>You're all set!</h3>
      <p>We've notified your administrator by email about the free trial ending.</p>
      <button (click)="done.emit()">Done</button>
    }
  }
</div>
