import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from 'src/app/_services/auth.service';
import { PlanPriceComponent } from './plan-price/plan-price.component';
import { TotalSearchesCardComponent } from './total-searches-card/total-searches-card.component';
import { PlanStatusPillComponent } from './plan-status-pill/plan-status-pill.component';
import { SubscriptionService } from 'src/app/_services/subscription.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';

@Component({
  selector: 'subscriptions',
  standalone: true,
  imports: [
    PlanPriceComponent,
    TotalSearchesCardComponent,
    PlanStatusPillComponent,
    FontAwesomeModule,
    UpgradeModalComponent
  ],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent {
  private readonly authService = inject(AuthService);
  isSuperUser = this.authService.superUser;
  manageBillingLinkIcon = faArrowUpRightFromSquare;

  showUpgradeModal = signal(false);
  showThankYouModal = signal(false);

  plan = signal({
    name: 'Free Trial',
    price: '$0',
    period: 'per month',
    description: 'Then $150 per month ($1800/year, billed annually)',
    daysLeft: 7
  });

  searches = signal({
    used: 35,
    total: 50,
    resetDate: '12/10/2024'
  });

  planStatus = computed(() => {
    return `${this.plan().daysLeft} days left`;
  });

  upgrade() {
    this.showUpgradeModal.set(true);
  }
}
