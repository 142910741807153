import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { FeatureService } from './feature.service';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable()
export class SuperAdminService {
  constructor(private featureService: FeatureService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return toObservable(this.featureService.getFeatures()).pipe(
      map(() => {
        const isSuperAdmin = this.featureService.enabled('super-admin');
        if (!isSuperAdmin) {
          this.router.navigate(['/error']);
          return false;
        }
        return true;
      })
    );
  }
}
